import React, {Component} from "react";
import {Backdrop, Button, CircularProgress, withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {withSnackbar} from 'material-ui-snackbar-provider';
import {withTranslation} from 'react-i18next';
import i18n from "i18next";

import customConstants from "Constants";
import {DataGrid} from "@material-ui/data-grid";
import moment from "moment";

const styles = theme => ({
    wrapper: {
        position: "relative",
        backgroundColor: '#fff'
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});


class UserApproval extends Component {
    state = {
        waitingUserList: [],
        loading: false
    }

    componentDidMount() {
        this.setState({loading: true});
        // get request will be for approval list
        axios.post(customConstants.API_URL + "/assess/list", {statusList: "WAITING"})
            .then((res) => {
                try {
                    if (res.data) {
                        res.data.forEach((el, k) => el.id = k);
                        this.setState({waitingUserList: res.data});
                    } else {
                        this.props.snackbar.showMessage(this.props.t("There is no user in the approval list"), 'error');
                    }
                    this.setState({loading: false});
                } catch (e) {
                    console.log(e)
                    this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                    this.setState({loading: false});
                }
            }).catch((e) => {
            this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
            this.setState({loading: false});
        })
    }

    deleteFromApprovalList = (requestId, status) => {
        this.setState({loading: true});
        axios.post(customConstants.API_URL + "/assess/respond-request", {
            "requestId": requestId,
            "status": status
        }).then((res) => {
            try {
                if (res.data) {
                    this.props.snackbar.showMessage(this.props.t("Operation Completed"), 'success');
                    this.componentDidMount();
                } else {
                    this.props.snackbar.showMessage(res?.response?.data?.message || this.props.t("The operation could not be performed due to an error."), 'error');
                    this.setState({loading: false});
                }
            } catch (e) {
                console.log(e)
                this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                this.setState({loading: false});
            }
        }).catch((e) => {
            console.log(e)
            this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
            this.setState({loading: false});
        })
    }


    render() {
        const columns = [
            {
                field: 'name_surname', headerName: this.props.t('Name Surname'),
                width: 200,
                renderCell(params) {
                    let nameSurname = "";
                    if (params.row.entity.name) {
                        nameSurname += params.row.entity.name;
                        nameSurname += " ";
                    }
                    if (params.row.entity.surname)
                        nameSurname += params.row.entity.surname;
                    return nameSurname;
                }

            },
            {
                field: 'username', headerName: this.props.t('E-mail Address'),
                width: 200,
                renderCell(params) {
                    return params.row.entity.username
                }
            },
            {
                field: 'roles', headerName: this.props.t('Role'),
                width: 150,
                renderCell(params) {
                    let userRoles = "";
                    for (let i = 0; i < params.row.entity.roles.length; ++i) {
                        if (params.row.entity.roles[i] === "agent_lead")
                            userRoles += "Agent Lead";
                        if (params.row.entity.roles[i] === "maker")
                            userRoles += "Maker"
                        if (params.row.entity.roles[i] === "checker")
                            userRoles += "Checker"
                        if (params.row.entity.roles[i] === "admin")
                            userRoles += "Admin"
                        if (params.row.entity.roles[i] === "agent")
                            userRoles += "Agent"
                        if (i != params.row.entity.roles.length - 1)
                            userRoles += " & "
                    }
                    return userRoles;
                }
            },
            {
                field: 'createdAt', headerName: this.props.t('Registration Date'),
                width: 250,
                renderCell(params) {
                    return moment(params.row.createdAt).local().locale(i18n.language).format('DD/MM/YYYY, HH:mm')
                }
            },
            {
                field: 'makerUsername', headerName: this.props.t('Maker'),
                width: 200,
            },
            {
                field: 'actions', headerName: this.props.t('Actions'), width: 200,
                renderCell: (params) => {
                    return (<>
                        <Button variant="contained"
                                onClick={() => this.deleteFromApprovalList(params.row.requestId, "APPROVED")} style={{
                            color: "white",
                            backgroundColor: "#71BD33",
                            margin: "0px 5px 0px"
                        }}>{this.props.t("Approve")}</Button>
                        <Button variant="contained"
                                onClick={() => this.deleteFromApprovalList(params.row.requestId, "DECLINED")} style={{
                            color: "white",
                            backgroundColor: "#E4362D",
                            margin: "0px 5px 0px"
                        }}>{this.props.t("Decline")}</Button>
                    </>)
                }
            }
        ];

        return (
            <section className="section_wrapper">
                <div className="section_header">
                    <h2>{this.props.t("Users Waiting for Approval")}</h2>
                </div>
                {(this.state.loading === true) ?
                    <Backdrop style={{
                        zIndex: 999,
                        color: '#fff'
                    }} open={this.state.loading}><CircularProgress
                        color="secondary"/></Backdrop> : null}
                <div className=" h-100 position-relative">
                    {/* <div className="gridHeader" style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <Button variant="contained" onClick={() => this.props.history.push("/")} style={{marginBottom:"20px", backgroundColor:"grey", color:"white"}}>{this.props.t("Back To Panel")}</Button>
                            </div>*/}
                    <div className="grid_wrapper">
                        <DataGrid rowHeight={60} rows={this.state.waitingUserList} columns={columns} pageSize={50}
                                  autoHeight={false}
                                  disableSelectionOnClick={true}
                        />
                    </div>
                </div>


            </section>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(withSnackbar()(withRouter(
    UserApproval
))));
