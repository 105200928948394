import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import queryString from "query-string";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    tr: {
        translation: {
            "Delete User" : "Kullanıcıyı Sil",
            "transactionType" : "İşlem Türü",
            "idType" : "Kimlik Türü",
            "handLandmarks" : "El Referans Noktası",
            "Are you sure you want to activate the user?" : "Kullanıcıyı aktife çekmek istediğinizden emin misiniz?",
            "Are you sure you want to deactivate the user?" : "Kullanıcıyı pasife almak istediğinizden emin misiniz?",
            "Switch User Status" : "Kullanıcının Durumunu Değiştir",
            "Pending for Deactivation" : "Pasife Alınmak İçin Bekliyor",
            "Users to be Deactivated" : "Pasife Alınacak Kullanıcılar",
            "You can not paste any text here!" : "Bu alana metin yapıştıramazsınız",
            "Close": "Kapat",
            "_newidfront": "Yeni Kimlik",
            "_hiddenphoto": "Gizli Fotoğraf",
            "prove_result": "Kanıtlanmış Sonuçlar",
            "user_details": "Kullanıcı Detayları",
            "id_number": "Kimlik Numarası",
            "MRZEXPDATECHECKSUMVALID": "Son Geçerlilik Tarihi Kontrol Basamağı Doğruluğu",
            "MRZBIRTHDATECHECKSUMVALID": "Doğum Tarihi Kontrol Basamağı Doğruluğu",
            "MRZDOCNUMCHECKSUMVALID": "Seri Numarası Kontrol Basamağı Doğruluğu",
            "HOLOGRAMSERIALSIMILARITY": "Hologram ile Seri Numarası Benzerlik Puanı",
            "HOLOGRAMBDATESIMILARITY": "Hologram ile Doğum Günü Benzerlik Puanı",
            "HOLOGRAMEDATESIMILARITY": "Hologram ile Son Geçerlilik Tarihi Benzerlik Puanı",
            "AGE": "Kimlik Fotoğrafı ile Yaş Tahmini",
            "GENDER": "Kimlik Fotoğrafı ile Cinsiyet Tahmini",
            "LIVENESSGENDER": "Canlılık ile Cinsiyet Tahmini",
            "LIVENESSAGE": "Canlılık ile Yaş Tahmini",
            "LIVENESSFACEBLACKLISTSCORE": "Canlılık ile Kara Listedeki Yüzlerin Benzerlik",
            "PHOTOCHEAT": "Kimlik Fotoğrafı Doğrulaması",
            "HOLOGRAM": "Hologram Algılama",
            "HOLOGRAMFACE": "Hologram ile Yüz Benzerlik Puanı",
            "HOLOGRAMIDSIMILARITY": "Hologram ile TCKN Benzerlik Puanı",
            "FACELIVENESSGESTURE": "Mimik Algılama",
            "FACELIVENESSFACECONSPER": "Canlılıkta Geçerli Yüz Oranı",
            "FACELIVENESSFACECONSAVG": "Canlılıkta Ortalama Yüz Benzerlik Puanı",
            "FACELIVENESSAI": "Canlılık Yapay Zeka Puanı",
            "FACEBLACKLISTSCORE": "Kimlik Fotoğrafı ile Kara Listedeki Yüzlerin Benzerliği",
            "FACELIVENESS": "Toplam Canlılık Skoru",
            "FACE": "Canlılık ile Yüz Benzerlik Puanı",
            "HIDDENPHOTO": "Gizli Fotoğraf Algılama",
            "SIGNATUREPHOTO": "İmza Algılama",
            "GUILLOCHEVERIFICATION": "Guilloche Algılama",
            "RAINBOWVERIFICATION": "Gökkuşağı Algılama",
            "MRZNFCSIMILARITY": "MRZ - NFC Eşleştirme",
            "MRZOCRSIMILARITY": "MRZ - OCR Eşleştirme",
            "DRIVERBARCODEIDMATCH": "Ehliyet Barkod Kontrolü",
            "IDCARDBARCODEIDMATCH": "Kimlik Barkod Kontrolü ",
            "IDDOCIDMATCH": "Belgenin TCKN Uyumu",
            "IDDOCNAMEMATCH": "Belgenin İsim Uyumu",
            "IDDOCSURNAMEMATCH": "Belgenin Soyisim Uyumu",
            "MRZBIRTHDATECHECKSUM": "Doğum Tarihi Kontrol Basamağı",
            "MRZEXPDATECHECKSUM": "Son Geçerlilik Tarihi Kontrol Basamağı",
            "MRZDOCNUMCHECKSUM": "Seri Numarası Kontrol Basamağı",
            "NFCMRZSIMILARITY": "NFC - MRZ Benzerliği",
            "NFCOCRFACESIMILARITY": "NFC - OCR Yüz Benzerliği",
            "NFCOCRSIMILARITY": "NFC - OCR Benzerliği",
            "AMLVALIDATION": "AML Kontrolü",
            "NVIPROVEIDWITHHOLDER": "NVI Kontrolü",
            "OCRLETTERCASEMATCH": "Beklenen Harf Büyüklüğü Kontrolü",
            "OCRMRZSIMILARITY": "OCR - MRZ Benzerliği",
            "OCRNFCFACESIMILARITY": " OCR - NFC Yüz Benzerliği",
            "OCRNFCSIMILARITY": "OCR - NFC Benzerliği",
            "OLDIDSECONDARYINFORMATION": "Beklenen Alan Uyumu Kontrolü",
            "IDCARD": "TR Kimlik Kartı",
            "OLDIDCARD": "Eski Kimlik",
            "PASSPORT": "Pasaport",
            "DRIVERCARD": "TR Ehliyet",
            "FOREIGNID": "İkamet İzin Belgesi",
            "FOREIGNIDTYPEA": "İkamet İzin Belgesi",
            "FOREIGNIDTYPEB": "İkamet İzin Belgesi",
            "FOREIGNIDTYPEC": "İkamet İzin Belgesi",
            "FOREIGNIDTYPED": "Geçici Koruma Belgesi",
            "SCREENATTACK": "Ekran Saldırısı Güvenliği",
            "hologram": "Hologram",
            "hiddenphoto": "Gizli Fotoğraf",
            "newidfront": "Yeni Kimlik Ön Yüzü (İşlenmiş)",
            "oldidfront": "Eski Kimlik Ön Yüzü (İşlenmiş) ",
            "signature": "İmza",
            "livenessframe": "Canlılık Tam Yüz",
            "passport": "Pasaport",
            "secondaryidfile": "Kimlik Arka Yüzü (İşlenmiş)",
            "face": "Canlılık Video",
            "newidface": "Portre",
            "nfcbiometricpicture": "Portre (NFC)",
            "driverface": "Sürücü Belgesi Portre",
            "newdriverfront": "Sürücü Belgesi",
            "turkishbluecardface": "Mavi Kart Portre",
            "turkishbluecardfront": "Mavi Kart Ön Yüz",
            "ocr": "OCR",
            "Name Surname": "Ad Soyad",
            "Registration Date": "Kayıt Tarihi",
            "Submitted Files": "Gönderilen Dosyalar",
            "Submit": "Kaydet",
            "Status": "Durum",
            "Verification Score": "Doğrulama Puanı",
            "Agent": "Müşteri Temsilcisi",
            "agent": "Temsilci",
            "agent_lead": "Temsilci Lideri",
            "admin": "Yönetici",
            "Control": "Kontrol",
            "Result": "Sonuç",
            "Outcome": "Çıktı",
            "Entry Type": "Giriş Türü",
            "Entry Score": "Giriş Puanı",
            "Entry Interpretation": "Giriş Yorumu",
            "Validation": "Doğrulama",
            "Acceptable": "Kabul Edilebilir",
            "Media Type": "Ortam Türü",
            "Actions": "Aksiyonlar",
            "Open": "Aç",
            "Download": "İndir",
            "Meta Title": "Üst Başlık",
            "Value": "Değer",
            "Info Title": "Bilgi Başlığı",
            "Couldn't get media information": "Medya bilgisi alınamadı",
            'The selected file is a ZIP archive and cannot be previewed directly. Please download the file to view its contents.': 'Seçilen dosya bir ZIP arşividir ve doğrudan önizlenemez. İçeriğini görüntülemek için lütfen dosyayı indirin.',
            "Couldn't get export": "Dışa aktarılamadı",
            "Identity Acceptable": "Kimlik Kabul Edilebilir",
            "Identity Information Validity": "Kimlik Bilgilerinin Geçerliliği",
            "Biometric Photo Validity": "Biyometrik Fotoğraf Geçerliliği",
            "Encryption Validity": "Şifreleme Geçerliliği",
            "Certificate Validity": "Sertifika Geçerliliği",
            "Created At": "Oluşturma Tarihi",
            "Name": "Ad",
            "Surname": "Soyad",
            "Birth Date": "Doğum Tarihi",
            "Document Type": "Doküman Tipi",
            "Identity No": "Kimlik No",
            "Expiry Date": "Son Geçerlilik Tarihi",
            "Delete Confirmation": "Silme Onayı",
            "The data you selected will be completely removed. Do you want to continue?": "Seçtiğiniz veriler tamamen kaldırılacak. Devam etmek istiyor musunuz?",
            "Cancel": "İptal",
            "Confirm": "Onayla",
            "Control Results": "Kontrol Sonuçları",
            "Confidence": "Doğruluk",
            "Identity": "Kimlik",
            "Video & Image": "Video ve Resim",
            "Metadata": "OCR Sonuçları & Üst Veri",
            "NFC Controls": "NFC Sertifika Kontrolleri",
            "Please Wait...": "Lütfen bekleyin...",
            "Details": "Detaylar",
            "Export": "Dışa Aktar",
            "Report": "Rapor",
            "Rows per page:": "Sayfa başına satır sayısı",
            "Home": "Anasayfa",
            "Person": "Kişi",
            "User Information": "Kullanıcı Bilgilerim",
            "Logout": "Çıkış Yap",
            "Support": "Destek",
            "Verifications": "Doğrulamalar",
            "Verification Details": "Doğrulama Detayları",
            "Verification Result": "Doğrulama Sonucu",
            "Awaiting Calls": "Bekleyen Aramalar",
            "Video Call": "Görüntülü Görüşme",
            "Call": "Ara",
            "Waiting Time": "Bekleme Zamanı",
            "You will return to back page. Do you want to continue?": "Giriş sayfasına geri döneceksiniz. Devam etmek istiyor musunuz?",
            "ID Info": "Kimlik Bilgisi",
            "NFC Info": "NFC Bilgisi",
            "Controls": "Kontroller",
            "Signing, Please Wait...": "Giriş yapılıyor, Lütfen bekleyiniz...",
            "Customers": "Müşteriler",
            "oldidface": "Eski Kimlik Yüz",
            "Search Name": "Ada Göre Arayınız",
            "Last Attempt": "Son Deneme",
            "Phone": "Telefon",
            "E-Mail": "E-Posta",
            "User": "Kullanıcı",
            "Customer Details": "Müşteri Detayı",
            "Transaction ID": "İşlem ID",
            "Verification Date": "Onaylama Tarihi",
            "city": "Şehir",
            "country": "Ülke",
            "device": "Cihaz",
            "ip": "IP",
            "language": "Dil",
            "Document No": "Seri No",
            "Licence Number": "Sürücü Sicil Numarası",
            "Passport No": "Pasaport No",
            "Awaiting Documents": "Bekleyen Belgeler",
            "Confirm Document": "Dokümanı Onayla",
            "Confirmed Documents": "Onaylanmış Belgeler",
            "Document Details": "Doküman Detayları",
            "Identity Number": "Kimlik Numarası",
            "This document was marked as CONFIRMED.": "Dokümanı GEÇERLİ olarak işaretledin.",
            "Do you confirm?": "Onaylıyor musun?",
            "Check Again": "Tekrar Kontrol Et",
            "This document was REJECTED.": "Dokümanı REDDETTİN.",
            "Choose the reason for rejection": "Red sebebini seçin",
            "The document has expired": "Belge geçerliliğini yitirmiş",
            "Document not found": "Belge bulunamadı",
            "not-found": "Belge bulunamadı",
            "Document type wrong": "Belge tipi yanlış",
            "Confirmation Date": "Onay Tarihi",
            "Confirmation Status": "Onay Durumu",
            "Save": "Kaydet",
            "Confirm or decline this document.": "Bu belgeyi onaylayın veya reddedin.",
            "Operation Completed": "İşlem Tamamlandı",
            "You are closing the selected document without making any choice. This document will not be available for confirmation for 3 minutes. Do you confirm?": "Bir işaretleme yapmadan seçtiğin dokümanı kapatıyorsun. Bu doküman 3 dakika boyunca onay için erişilebilir olmayacak. Kapatma işlemini onaylıyor musun?",
            "Go back to the document": "Dokümana Geri Dön",
            "Change as Declined": "Reddedildi Olarak Değiştir",
            "Change as Approved": "Onaylandı Olarak Değiştir",
            "Do you want to change?": "Değiştirmek istiyor musunuz?",
            "Document current status": "Dökümanın güncel durumu",
            "DECLINED": "REDDEDİLDİ",
            "Personel Incorrect Entry": "Personel Hatalı Giriş",
            "Document Barcode Number Incorrect Entry": "Döküman Barkod Numarası Hatalı Giriş",
            "TCKN Incorrect Entry": "TCKN Hatalı Giriş",
            "Why is the document's \"Approved\" status changed to \"Declined\"?": "Dökümanın \"Onaylandı\" durumunun \"Reddedildi\" olarak değiştirme sebebi nedir?",
            "Why is the document's \"Declined\" status changed to \"Approved\"?": "Dökümanın \"Reddedildi\" durumunun \"Onaylandı\" olarak değiştirme sebebi nedir?",
            "Date": "Tarih",
            "Action": "Aksiyon",
            "Document History": "Belge Geçmişi",
            "Blue Card": "Mavi Kart",
            "Residence Permit": "İkamet İzni",
            "Temporary Protection ID": "Geçici Koruma Belgesi",
            "German ID Card": "Almanya Kimlik Kartı",
            "Approved": "Onaylandı",
            "Document Detail": "Belge Detayı",
            "Verification Detail": "Doğrulama Detayı",
            "Change Status": "Onay Durumunu Değişir",
            "Please enter same password": "Lütfen aynı şifreyi girin",
            "Back To Panel": "Panele Dön",
            "Could not change your password": "Şifreniz değiştirilemedi",
            "Old Password": "Eski Şifre",
            "New Password": "Yeni Şifre",
            "Confirm New Password": "Yeni Şifreyi Doğrula",
            "Save Password": "Şifremi kaydet",
            "Change Password": "Şifre Değiştir",
            "Forgot Password?": "Şifreni mi unuttun?",
            "Reset Password": "Şifremi Sıfırla",
            "E-mail": "E-posta",
            "Return": "Geri Dön",
            "Create New Password": "Yeni Şifre Oluştur",
            "Could not reset your password": "Şifreniz sıfırlanamadı",
            "Check your E-mail": "E-postanızı kontrol edin",
            "Check your username": "Kullanıcı adınızı kontrol edin",
            "Username": "Kullanıcı adı",
            "Mobile Number": "Telefon numarası",
            "Roles": "Roller",
            "All Users": "Tüm Kullanıcılar",
            "Sign In": "Giriş Yap",
            "Password": "Şifre",
            "Remember Me": "Beni hatırla",
            "Create User": "Kullanıcı Oluştur",
            "Approve Users": "Kullanıcı Onayla",
            "Approve": "Onayla",
            "Decline": "Reddet",
            "Create": "Oluştur",
            "User Approval Screen": "Onay Bekleyen Kullanıcılar",
            "The document is not original": "Belge orijinal değil",
            "Select a reason for rejection of the document": "Belgenin reddedilmesi için bir neden seçin",
            "Uploaded": "Yüklendi",
            "Awaiting": "Bekliyor",
            "Declined: Expired document": "Reddedildi: Geçerli değil",
            "Declined: Document not found": "Reddedildi: Bulunamadı",
            "Declined: Document type wrong": "Reddedildi: Yanlış tip",
            "Declined: Document is not original": "Reddedildi: Belge orijinal değil",
            "Changed to declined: TCKN Incorrect Entry": "Reddedildi olarak değiştirildi: TCKN hatalı giriş",
            "Changed to declined: Document Barcode Number Incorrect Entry": "Reddedildi olarak değiştirildi: Barkod hatalı giriş",
            "Changed to declined: Personel Incorrect Entry": "Reddedildi olarak değiştirildi: Personel hatalı giriş",
            "Declined": "Reddedildi",
            "Changed to approved: Personel Incorrect Entry": "Onaylandı olarak değiştirildi: Personel hatalı giriş",
            "All": "Hepsi",
            "Clear All": "Hepsini temizle",
            "Search": "Ara",
            "Additional Info": "Ek Bilgiler",
            "Phone Number": "Telefon numarası",
            'identity_no': 'Kimlik No',
            'name': 'Ad',
            'surname': 'Soyad',
            'birthdate': 'Doğum Günü',
            'document_no': 'Döküman No',
            'valid_until': 'Geçerlilik',
            'nationality': 'Uyruk',
            "gender": "Cinsiyet",
            "mother_name": "Anne Adı",
            "father_name": "Baba Adı",
            "birth_place": "Doğum Yeri",
            "alternativeName": "Alternatif Ad",
            "alternativeSurname": "Alternatif Soyad",
            "The operation could not be performed due to an error.": "Alınan bir hata sebebiyle işlem gerçekleştirilemedi.",
            "We Value Your Privacy Preferences": "Kişisel Verilerin Korunması ve İşlenmesine Yönelik Aydınlatma",
            "This website gathers information about user interaction, personel information and biometric characteristics for secure consent collection.  This sensitive information will only enable you to sign documents securely and verifiably and will not be shared with third parties without your consent. Please confirm your consents to proceed.": "Özel hayatınızın gizliliğine saygı duyuyor ve önem veriyoruz. Bu nedenle, “temel hak ve özgürlüklerinizi korumak amacıyla yürürlükte olan kanun” kapsamındaki kişisel verilerinizin kullanımı hakkında onayınızı almak istiyoruz.",
            "I have read and accepted the GDPR Complience": "KVKK Aydınlatma Metni’ni okudum ve kabul ediyorum",
            "Transaction Id": "İşlem kodu",
            "Gesture": "Mimik",
            "Please select valid date ranges": "Lütfen geçerli tarih aralığ seçiniz",
            "ID Type": "Kimlik Tipi",
            "Progress": "İlerleme",
            "ID Front": "Kimlik ön yüz",
            "ID Back": "Kimlik arka yüz",
            "Address Document": "Adres Dökümanı",
            "Biometric Recognition & Verification": "Biyometrik Tanıma & Doğrulama",
            "Please select one of the following identification documents for verification.": "Doğrulama için lütfen aşağıdaki kimlik belgelerinden birini seçin.",
            "For verification, first your ID photo, then your face photo is taken": "Doğrulama için önce kimlik fotoğrafınız, daha sonra yüzünüzün fotoğrafı çekilir",
            "The verification result is created by evaluating the identity and face information.": "Kimlik ve yüz bilgilerini değerlendirilerek doğrulama sonucu oluşturulur",
            "Authentications without user creation cannot be connected with any account and all information is deleted when you leave the page": "Kullanıcı oluşturmadan yapılan doğrulamalar herhangi bir hesapla bağlanamaz ve sayfayı terk ettiğinizde tüm bilgiler silinir",
            "Authentications made by creating a user are linked to the user account and the results can be viewed through the ProveID Dashboard panel.": "Kullanıcı oluşturularak yapılan doğrulamalar kullanıcı hesabına bağlanır ve sonuçlar ProveID Dashboard paneli üzerinden izlenebilir.",
            "EXPIREDOC": "Belge geçerliliği yitirmiş",
            "NOTFOUNDDOC": "Belge bulunamadı",
            "WRONGDOCTYPE": "Belge tipi yanlış",
            "NOTORIGINAL": "Belge orijinal değil",
            'select_language': 'Ülke Seçiniz',
            'select_language_desc': 'Lütfen kimlik kartı ile doğrulmaya devam etmek için ülkenizi seçiniz.',
            'select_template': 'Şablon Tipini seçin',
            'select_template_desc': 'Kimlik kart doğrulaması ile devam etmek için lütfen şablon tipinizi seçiniz.',
            'FOREIGNIDBACK': 'Yabancı Kimlik (Arka)',
            'Go Back To Dashboard': 'Yönetim Paneline Geri Dön',
            "ID-Front": "Kimlik Kartı Ön Yüz",
            "ID-Back": "Kimlik Kartı Arka Yüz",
            "ID": "Kimlik Kartı",
            "ID-NFC": "NFC Okuma",
            "Hologram": "Hologram Videosu",
            "Others": "Diğer Kontroller",
            "Order": "Sıra",
            "Prev": "Geri",
            "Next": "İleri",
            "Media from the App": "Uygulamada Alınan Medyalar",
            "Media from the Video Call": "Görüntülü Görüşmede Alınan Medyalar",
            "Compare All Media": "Tüm Medyaları Karşılaştır",
            "Start Time (Newest → Oldest)": "Başlama zamanına göre (En yeni → En eski)",
            "Start Time (Oldest → Newest)": "Başlama zamanına göre (En eski → En yeni)",
            "Updated Time (Newest → Oldest)": "Güncellenme zamanına göre (En yeni → En eski)",
            "Updated Time (Oldest → Newest)": "Güncellenme zamanına göre (En eski → En yeni)",
            "By First Name (A → Z)": "Ada göre (A → Z)",
            "By First Name (Z → A)": "Ada göre (Z → A)",
            "By Last Name (A → Z)": "Soyada göre (A → Z)",
            "By Last Name (Z → A)": "Soyada göre (Z → A)",
            "Media": "Medyalar",
            "Control Details": "Kontrol Sonuçları",
            "Call Ended": "Görüşme Sonlandırıldı",
            "Take Photo Preview": "Fotoğraf Önizleme",
            "Shot Again": "Tekrar Çek",
            "Save and Continue": "Kaydet ve Devam Et",
            "Please wait... Capturing photo!": "Lütfen bekleyiniz... Fotoğraf çekiliyor",
            "Video Recording Stopped": "Video kaydı sonlandırıldı",
            "Video Recording Started": "Video kaydı başlatıldı",
            "Photo successfully captured": "Fotoğraf başarıyla çekildi",
            "Photo Successfully Saved": "Fotoğraf başarıyla kaydedildi",
            "Microphone Muted": "Mikrofon kapatıldı",
            "Microphone Unmuted": "Mikrofon açıldı",
            "vcface": "Yüz foto",
            "vcidfront": "Kimlik ön yüz",
            "vcidback": "Kimlik arka yüz",
            "vcidclosed": "Kimlik Kapatma Fotoğrafı",
            "vcidfrontphotoblocked": "Kimlik ön yüz fotoğraf kapatma",
            "vcidfrontsignatureblocked": "Kimlik ön yüz imza kapatma",
            "vcidfronthiddenphotoblocked": " Kimlik ön yüz gizli foto kapatma",
            "vcidfrontstarandcrescentblocked": "Kimlik ön yüz ay yıldız kapatma",
            "vcidbackbarcodeblocked": "Kimlik arka yüz barkod kapatma",
            "vcliveness": "Kimlikli selfie",
            "vcrecord": "Video",
            "Refresh": "Yenile",
            "Table export": "Tabloyu dışa aktar",
            "Filter": "Filtrele",
            "Sort": "Sırala",
            "ID No": "TCKN",
            "End Call": "Görüşmeyi Sonlandır",
            "Close Video": "Kameranı Kapat",
            "Open Video": "Kameranı Aç",
            "Close Mic": "Mikrofonunu Kapat",
            "Open Mic": "Mikrofonunu Aç",
            "Close Customer Mic": "Müşterinin Mikrofonunu Kapat",
            "Open Customer Mic": "Müşterinin Mikrofonunu Aç",
            "Flip Customer Camera": "Müşterinin Kamerasını Değiştir",
            "Take Photo": "Resim Çek",
            "Start Recording": "Kaydı Başlat",
            "Stop Recording": "Kaydı Durdur",
            "Agent Availability": "Durum",
            "Completed Calls": "Tamamlanan görüşmeler",
            "Awaiting Video Calls": "Bekleyen görüşmeler",
            "ACTIVE": "Aktif",
            "Active": "Aktif",
            "PASSIVE": "Pasif",
            "Passive": "Pasif",
            "Deactivated": "Pasif",
            "Out of Office": "Mesai Dışı",
            "Start Next Call": "Sıradaki görüşmeyi başlat",
            "Refresh Page": "Sayfayı Yenile",
            "Choose call status": "Görüşme durumunu seç",
            "CONFIRMED": "Onaylandı",
            "TECHNICAL_FAILURE": "Teknik bir arıza sebebiyle devam edilemedi",
            "CUSTOMER_FAILURE": "Müşteri sebebiyle devam edilemedi",
            "Edit Video Call Scripts": "Görüntülü Görüşme Metinlerini Düzenle",
            "Video Call Scripts": "Görüntülü Görüşme Metinleri",
            "Insert New Step": "Yeni Adım Ekle",
            "Step No": "Adım No",
            "Step Detail": "Adım Adı",
            "_Status": "Statü",
            "Course": "Akış",
            "Edit This Step": "Adımı Düzenle",
            "Edit This Role": "Rolü Düzenle",
            "Delete This Step": "Adımı Sil",
            "Select course to add step to": "Adım eklenecek akışı seç",
            "Add New Step": "Yeni Adım Ekle",
            "Add New Role": "Yeni Rol Ekle",
            "Static": "Statik",
            "Random": "Rastgele",
            "Random Id Data": "Rastgele Kimlik Bilgisi",
            "Script Type": "Script Tipi",
            "Agent Script": "Agent Scripti",
            "Agent Text": "Agent Bilgi Metni",
            "Mandatory Action": "Zorunlu Aksiyon",
            "Face Photo": "Yüz Fotoğrafı",
            "ID Front Photo": "Kimlik Ön Yüz Fotoğrafı",
            "ID Back Photo": "Kimlik Arka Yüz Fotoğrafı",
            "ID Closed Photo": "Kimlik Kapatma Fotoğrafı",
            "Confirm-Decline": "Onayla-Reddet",
            "Successful Action": "Başarılı Aksiyon",
            "Failed Action": "Başarısız Aksiyon",
            "Next Step": "Sonraki Adım",
            "See You Again": "Tekrar Bekleriz",
            "Id Data": "Kimlik Bilgisi",
            "Add New Option": "Yeni Seçenek Ekle",
            "Are you sure you want to close without saving?": "Kaydetmeden kapatmak istediğinize emin misiniz?",
            "Exit Without Saving": "Kaydetmeden Çık",
            "Back to Page": "Sayfaya Dön",
            "A": "Aktif",
            "D": "Pasif",
            "kyc-standard-tr": "KYC - Standart TR",
            "kyc-disabled-tr": "KYC - Engelli TR",
            "Save Changes": "Değişiklikleri Kaydet",
            "Incomplete": "Tamamlanmadı",
            "Completed": "Tamamlandı",
            "No video call": "Görüntülü görüşme mevcut değil",
            "Create Demo Link": "Demo Linki Oluştur",
            "Pending Verifications": "Kontrol Bekleyen Doğrulamalar",
            "Transaction Time": "İşlem Zamanı",
            "Checker": "Checker",
            "Verification Summary": "Değerlendirme Özeti",
            "Customer": "Müşteri",
            "insufficient_media": "Medya eksik",
            "all_media": "Eksik medya yok",
            "random_question": "Rastgele Soru",
            "Back to List": "Listeye Dön",
            "Score": "Not",
            "Compare": "Karşılaştır",
            "AWAITING": "Onay Bekleyen",
            "Pending Documents": "Kontrol Bekleyen Belgeler",
            "review": "Onay Bekliyor",
            "Address Document Control": "Adres Belgesi Kontrolü",
            "The document is valid": "Belge geçerli",
            "Document": "Belge",
            "None": "Yok",
            "Section Checks": "Bölümler Kontrolü",
            "Verification Code": "Doğrulama Kodu",
            "Verification Code resent": "Doğrulama Kodu tekrar gönderildi",
            "Enter 6 characters": "6 karakter giriniz",
            "Time left": "Kalan süre",
            "The code has expired. Resend.": "Kodun süresi bitti. Tekrar yolla.",
            "Clear": "Temizle",
            "Attempted Logins": "Başarısız Denemeler",
            "login_attempt": "{{ date }} tarihinde kullanıcınıza ait başarısız giriş denemesi bulunuyor.",
            "HEAD-HORIZONTAL": " - Kafa Sağa Sola Oynatma Mimiği Videosu",
            "HEAD-VERTICAL": " - Kafa Yukarı Aşağı Oynatma Mimiği Videosu",
            "EYE": " - Göz Mimiği Videosu",
            "MOUTH": " - Ağız Mimiği Videosu",
            "HH": " - Kafa Sağa Sola Oynatma Mimiği Videosu",
            "HV": " - Kafa Yukarı Aşağı Oynatma Mimiği Videosu",
            "E": " - Göz Mimiği Videosu",
            "M": " - Ağız Mimiği Videosu",
            "Please wait... Saving photo!": "Lütfen bekleyiniz... Fotoğraf kaydediliyor!",
            "My Info": "Bilgilerim",
            "Edit": "Düzenle",
            "Back Office": "Arka Ofis",
            "User Management": "Kullanıcı Yönetimi",
            "Video Call Management": "Görüntülü Görüşme Yönetimi",
            "Go to Web SDK Demo": "Web SDK Demoya Git",
            "Invalid": "Geçersiz",
            "Update Settings": "Bilgilerimi Güncelle",
            "Your session has expired": "Oturumunuz sonlandırılmıştır.",
            "Role Management": "Rol Yönetimi",
            "Role Name": "Rol adı",
            "Acceptance Threshold": "Kabul Eşiği",
            "Security Threshold": "Güvenlik Eşiği",
            "Control Name": "Kontrol Adı",
            "No Role": "Ek rol yok",
            "Thresholds": "Eşik Değerleri",
            "Remove User" : "Kullanıcıyı Sil",
            "The user you selected will be removed. Do you want to continue?" : "Seçtiğiniz kullanıcı kaldırılacaktır. Devam etmek istiyor musun?",
            "Users To Be Deleted" : "Silinecek Kullanıcılar",
            "Users To Be Cancelled" : "İptal Edilecek Kullanıcılar",
            "Users To Be Updated" : "Güncellenecek Kullanıcılar",
            "Do you want to continue?" : "Devam etmek istiyor musun?",
            "Confirmation" : "Onayla",
            "Request Sent" : "Talep Gönderildi",
            "Pending For Deletion" : "Silinmek İçin Bekliyor",
            "Pending For Cancellation" : "Passifleştirilmek İçin Bekliyor",
            "Pending For Update" : "Güncellenmek İçin Bekliyor",
            "new_id": "Yeni Kimlik Ön Yüz",
            "new_id_back": "Yeni Kimlik Arka Yüz",
            "old_id": "Eski Kimlik Ön Yüz",
            "old_id_back": "Eski Kimlik Arka Yüz",
            "new_driver": "Yeni Sürücü Belgesi Ön Yüz",
            "new_driver_back": "Yeni Sürücü Belgesi Arka Yüz",
            "old_driver": "Eski Sürücü Belgesi Ön Yüz",
            "old_driver_back": "Eski Sürücü Belgesi Arka Yüz",
            "address_confirmation_document": "Adres Doğrulama Belgesi",
            "signature_document": "İmza",
            "Job": "Meslek",
            "External System Id": "Harici Sistem Kodu",
            "Address": "Adres",
            "External Customers": "Harici Müşteriler",
            "browser": "Tarayıcı",
            "mobile": "Mobil",
            "Update User": "Kullanıcı Güncelle",
            "answeredCallsCount": "Cevaplanan Çağrı",
            "answeredCallsRatio": "Cevaplanma Oranı (%)",
            "answeredCallsTotalWaitingTime": "Cevaplanan Çağrılarda Toplam Bekleme Süresi",
            "avgAnsweredCallsWaitingTime": "Cevaplanan Çağrılarda Ortalama Bekleme Süresi",
            "avgMeetingTime": "Ortalama Görüşme Süresi",
            "avgWaitingTime": "Ortalama Bekleme Süresi",
            "maxWaitingTime": "Maks. Bekleme Süresi",
            "totalCallsCount": "Toplam Çağrı",
            "totalMeetingTime": "Toplam Görüşme Süresi",
            "totalWaitingTime": "Toplam Bekleme Süresi",
            "Date Range": "Tarih aralığı",
            "Queue Based Call History Report": "Kuyruk Bazında Çağrı Raporu",
            "Clear Filters": "Temizle",
            "Create Report": "Rapor Oluştur",
            "inbound.answeredCalls": "Cevaplanan Çağrı Sayısı",
            "inbound.rejectedCalls": "Reddedilen Çağrı Sayısı",
            "inbound.totalMeetingTime": "Toplam Görüşme Süresi",
            "inbound.avgMeetingTime": "Ortalama Görüşme Süresi",
            "inbound.totalWaitingTime": "Toplam Çağrı Cevaplama Süresi",
            "inbound.avgWaitingTime": "Ortalama Çağrı Cevaplama Süresi",
            "totalActiveTime": "Toplam Aktif Süresi",
            "totalPassiveTime": "Toplam Pasif Süresi",
            "hostFullName": "Uzman",
            "Call Statistics Based On Agent": "Uzman Bazında Çağrı İstatistikleri",
            "call_no": 'Çağrı No',
            "queueInfo.queueName": 'Kuyruk Adı',
            "host.fullname": 'Çağrıyı karşılayan kişi',
            "customer.fullName": 'Müşteri',
            "queueEntryDate": 'Kuyruğa Giriş Zamanı',
            "waitingTime": 'Bekleme Süresi',
            "meetingTime": 'Görüşme süresi',
            "appointmentId": 'Randevu No',
            "$appointmentId": 'Durum',
            "Queue Based Call Detail": "Kuyruk Bazında Gelen Çağrı Detayı",
            "availableAgents": "Müsait temsilciler",
            "host.fullName": "Çağrıyı karşılayan kişi",
            "queueName": "Kuyruk Adı",
            "customerFullname": "Müşteri",
            "callReceivedTime": "Temsilciye Ulaşma zamanı",
            "meetingCompletedDate": "Görüşme Bitiş Zamanı",
            "response": "Durum",
            "candidateQueueMeetingInfoId": "Çağrı No",
            "Call Details Based On Agent": "Uzman Bazında Gelen Çağrı Detayı",
            "Video Call Reports": "Görüntülü Görüşme Raporları",
            "Transaction re-opening rate of the same TR ID number": "Aynı TCKN'nin tekrar transaction açma oranı",
            "Retry #": "Tekrar sayısı",
            "Customer acquisition rate": "Müşteri olma oranı",
            "Number of TR ID numbers that initiate KYC": "KYC başlatan TCKN sayısı",
            "Number of TR ID numbers that became customers": "Müşteri olan TCKN sayısı",
            "Customer Loss": "Müşteri kaybı",
            "Step failure rate": "Başarısız adım oranı",
            "Channel Type Distribution": "Akış tipi dağılımı",
            "With NFC": "NFC'li",
            "Without NFC": "NFC'siz",
            "General statistics": "Genel İSTATİSTİKLER",
            "Controls statistics": "Kontrol İSTATİSTİKLERİ",
            "Step statistics": "Adım İSTATİSTİKLERİ",
            "Today": "Bugün",
            "Last 7 Days": "Son 7 Gün",
            "Last 30 Days": "Son 30 Gün",
            "Completed all steps": "Tüm adımları tamamlayanlar",
            "Did not proceed beyond the liveness step": "Canlılık adımından sonrasına geçemeyenler",
            "Did not proceed beyond the NFC step": "NFC adımından sonrasına geçemeyenler",
            "Did not proceed beyond the hologram step": "Hologram adımından sonrasına geçemeyenler",
            "Did not proceed beyond the card front face capture step": "Ön Yüz kart yakalama adımından sonrasına geçemeyenler",
            "Did not proceed beyond the card back face capture step": "Arka Yüz kart yakalama adımından sonrasına geçemeyenler",
            "Hourly / Last 24 Hours": "Saatlik / Son 24 saat",
            "Weekly / 7 Days": "Haftalık / Son 7 gün",
            "Monthly / Last 31 Days": "Aylık / Son 31 gün",
            "Reports": "Raporlar",
            "Verification Reports": "Doğrulama Raporları",
            "Mimic \\ Retry #": "Mimik \\ Tekrar Sayısı",
            "Controls \\ Score range": "Kontroller \\ Skor Aralığı",
            "Certificate Controls": "Sertifika Kontrolleri",
            "Scores greater than specified value pass": "Belirtilen değerden daha yüksek skorlar geçer",
            "Scores less than specified value pass": "Belirtilen değerden daha düşük skorlar geçer",
            "No device info": "Cihaz bilgisi yok",
            "No process info": "İşlem bilgisi yok",
            "Yesterday": "Dün",
            "SCREENATTACKBACK":"Ekran Saldırısı (Arka Yüz)",
            "MRZCOMPOSITECHECKSUM":"Bileşim Kontrol Basamağı",
            "MRZCOMPOSITECHECKSUMVALID":"Bileşim Kontrol Basamağı Doğruluğu",
            "FACEPHOTOEXISTENCE":"Fotoğraf Bulunma Kontrolü",
            "CHIPDETECTION":"Çip Bulunma Kontrolü",
            "Label": "Açıklama",
            "Field": "Alan",
            "Previous Value": "Eski Değer",
            "New Value": "Yeni Değer",
            "Update": "Güncelle",
            "View": "Görüntüle",
            "Date of Latest Transaction Processed for Reporting": "Raporlama İçin İşlenen Son İşlem Tarihi",
            "Start Reporting Service": "Raporlama Servisini Başlat",
            "Reporting Service": "Raporlama Servisi",
            "Report Settings": "Rapor Ayarları",
            "Are you sure you want to delete the user?": "Kullanıcıyı silmek istediğinizden emin misiniz?",
            "There is no need for the document to be checked. The document will be removed from the list.": "Dokümanın incelenmesine gerek bulunmamaktadır. Doküman, listeden çıkarılacaktır.",
            "Outdated Document": "Geçersiz Doküman",
            "Password should be at least 9 characters and include lower case upper case letter number and special character.": "Şifreniz en az 9 karakterden oluşmalı ve en az bir büyük harf, küçük harf, rakam ve özel karakter içermeli.",
            "Current Threshold": "Mevcut Eşik",
            "Passed": "Geçti",
            "Failed": "Başarısız",
            "Hand" : "Kimliği Parmakla Kapatma",
            "Card-Hold-Liveliness" : "Kartı Tutarak Canlılık",
            "HANDLANDMARKTYPEMATCHTHUMBRIGHTDOWN" : "Parmakla Kapatmada Kimlik Tipi Kontrolü",
            "HANDLANDMARKSCORE THUMBRIGHTDOWN" : "Kimliğin Sağ Alt Köşesini Parmakla Kapatma Kontrolü",
            "HANDLANDMARKTYPEMATCH THUMBRIGHTUP" : "Parmakla Kapatmada Kimlik Tipi Kontrolü",
            "HANDLANDMARKSCORE THUMBRIGHTUP" : "Kimliğin Sağ Üst Köşesini Parmakla Kapatma Kontrolü",
            "HANDLANDMARKTYPEMATCH THUMBLEFTDOWN" : "Parmakla Kapatmada Kimlik Tipi Kontrolü",
            "HANDLANDMARKSCORE THUMBLEFTDOWN" : "Kimliğin Sol Alt Köşesini Parmakla Kapatma Kontrolü",
            "HANDLANDMARKTYPEMATCH THUMBLEFTUP" : "Parmakla Kapatmada Kimlik Tipi Kontrolü",
            "HANDLANDMARKSCORE THUMBLEFTUP" : "Kimliğin Sol Üst Köşesini Parmakla Kapatma Kontrolü",
            "LWICONLYFRONCARDTYPESIMILARITY" : "Kartlı Canlılıkta Kart Tipi Kontrolü",
            "LWICONLYFRONTFACESIMILARITY (A-D)" : "Kartlı Canlılıkta Yüz Benzerlik Puanı",
            "LWICONLYFRONTOCRSIMILARITY" : "Kartlı Canlılıkta OCR Benzerlik Kontrolü",
            "LWICLIVENESSAI" : "Kartlı Canlılıkta Yapay Zeka Puanı",
            "LWICSELFIEFIRSTIDFACESIMILARITY(A-C)" : "Kartlı Canlılıkta Kart Üstündeki Yüz Benzerliği",
            "PHOTOSHOPCONTROLBACK" : "Photoshop Kontrolü Arka Yüz",
            "PHOTOSHOPCONTROL" : "Photoshop Kontrolü Ön Yüz",
            "APPROVAL" : "Onay Metni Kontrolü",
            "SERIALNUMBER" : "Seri Numarası Kontrolü",
            "THUMBRIGHTUP" : "Parmakla Kapatma Kontrolü",
            "THUMBLEFTUP" : "Parmakla Kapatma Kontrolü",
            "THUMBLEFTDOWN" : "Parmakla Kapatma Kontrolü",
            "THUMBRIGHTDOWN" : "Parmakla Kapatma Kontrolü",
            "THUMBRIGHTLEFTUP" : "Parmakla Kapatma Kontrolü",
            "THUMBRIGHTLEFTDOWN" : "Parmakla Kapatma Kontrolü",
            "livenessonlyface" : "Kartı Tutarak Canlılık Kimlik",
            "livenesswithidfront" : "Kartı Tutarak Canlılık",
            "LWICONLYFRONTFACESIMILARITY" : "Kartlı Canlılıkta Kimlikler Arası Yüz Benzerlik Puanı",
            "LWICSELFIEFIRSTIDFACESIMILARITY" : "Kartlı Canlılıkta Yüz Benzerlik Puanı",
            "Voice" : "Ses Kontrolü Adımları",
            "FWVLIVENESSAI SERIALNUMBER" : "Seri Numarası Kontrolünde Yapay Zeka Puanı",
            "FWVLIVENESSAI APPROVAL" : "Onay Metni Kontrolünde Toplam Canlılık Skoru",
            "FWVLIVENESSGESTURE SERIALNUMBER" : "Seri Numarası Kontrolünde Mimik Algılama",
            "FWVLIVENESSFACECONSAVG SERIALNUMBER" : "Seri Numarası Kontrolünde Ortalama Yüz Benzerlik Puanı",
            "FWVLIVENESSFACECONSPER SERIALNUMBER" : "Seri Numarası Kontrolünde Geçerli Yüz Oranı",
            "FWVFACE SERIALNUMBER" : "Seri Numarası Kontrolünde Yüz Benzerlik Puanı",
            "FWVAGE SERIALNUMBER" : "Seri Numarası Kontrolünde Yaş Tahmini",
            "FWVGENDER SERIALNUMBER" : "Seri Numarası Kontrolünde Cinsiyet Tahmini",
            "FWVSPEECHMATCH SERIALNUMBER" :"Seri Numarası Kontrolünde Söylem Doğruluk Kontrolü",
            "FWVLIVENESS APPROVAL" : "Onay Metni Kontrolünde Toplam Canlılık Skoru",
            "FWVLIVENESSGESTURE APPROVAL" : "Onay Metni Kontrolünde Mimik Algılama",
            "FWVLIVENESSFACECONSAVG APPROVAL" : "Onay Metni Kontrolünde Ortalama Yüz Benzerlik Puanı",
            "FWVLIVENESSFACECONSPER APPROVAL" : "Onay Metni Kontrolünde Geçerli Yüz Oranı",
            "FWVFACE APPROVAL" : "Onay Metni Kontrolünde Yüz Benzerlik Puanı",
            "FWVAGE APPROVAL" : "Onay Metni Kontrolünde Yaş Tahmini",
            "FWVGENDER APPROVAL" : "Onay Metni Kontrolünde Cinsiyet Tahmini",
            "FWVSPEECHMATCH APPROVAL" : "Onay Metni Kontrolünde Söylem Doğruluk Kontrolü",
            "FWVSPEECHCONSISTENCY APPROVAL" : "Onay Metni Kontrolünde Ses Uyumluluk Kontrolü",
            "SECURITYOBJECTVERIFICATION" : "Güvenlik Öğeleri Yorumu",
            "External Id" : "Sicil No",
            "HANDLANDMARKTYPEMATCH THUMBRIGHTDOWN" : "Parmakla Kapatmada Kimlik Tipi Kontrolü",
        }
    },
    en: {
        translation: {
            "Do you want to decline to deactivate user {{user}}?" : "Do you want to decline to deactivate user {{user}}?",
            "Do you want to confirm to deactivate user {{user}}?" : "Do you want to confirm to deactivate user {{user}}?",
            "transactionType" : "Transaction Type",
            "idType" : "ID Type",
            "handLandmarks" : "Hand Landmark",
            "Close": "Close",
            "_newidfront": "TR New ID",
            "_hiddenphoto": "Hidden Photo",
            "prove_result": "Prove Results",
            "user_details": "User Details",
            "id_number": "Id Number",
            "Your session timed out. Please re-login": "Your session timed out. Please re-login",
            "MRZEXPDATECHECKSUMVALID": "Expiry Date Check Digit Validity",
            "MRZBIRTHDATECHECKSUMVALID": "Date of Birth Check Digit Validity",
            "MRZDOCNUMCHECKSUMVALID": "Document Number Check Digit Validity",
            "HOLOGRAMSERIALSIMILARITY": "Serial Number Similarity Score via Hologram",
            "HOLOGRAMBDATESIMILARITY": "Birth Date Similarity Score via Hologram",
            "HOLOGRAMEDATESIMILARITY": "Expiry Date Similarity Score via Hologram",
            "AGE": "Age Prediction via ID Photo",
            "GENDER": "Gender Prediction via ID Photo",
            "LIVENESSAGE": "Age Prediction via Liveness",
            "LIVENESSFACEBLACKLISTSCORE": "Similarity to Blacklisted Faces via Liveness",
            "LIVENESSGENDER": "Gender Prediction via Liveness",
            "HOLOGRAMIDSIMILARITY": "ID No Similarity Score via Hologram",
            "PHOTOCHEAT": "ID Photo Verification",
            "HOLOGRAM": "Hologram Detection",
            "HOLOGRAMFACE": "Face Similarity Score via Hologram",
            "FACELIVENESSFACECONSPER": "Valid Face Ratio at Liveness",
            "FACELIVENESSFACECONSAVG": "Average Face Similarity Score at Liveness",
            "FACEBLACKLISTSCORE": "Similarity to Blacklisted Faces via ID Photo",
            "FACELIVENESS": "Total Liveness Score",
            "FACE": "Face Similarity Score via Liveness",
            "HIDDENPHOTO": "Hidden Photo Detection",
            "SIGNATUREPHOTO": "Signature Detection",
            "GUILLOCHEVERIFICATION": "Guilloche Detection",
            "RAINBOWVERIFICATION": "Rainbow Detection",
            "MRZNFCSIMILARITY": "MRZ - NFC Similarity",
            "MRZOCRSIMILARITY": "MRZ - OCR Similarity",
            "DRIVERBARCODEIDMATCH": "Driving Licence Barcode Check",
            "IDCARDBARCODEIDMATCH": "ID Card Barcode Check",
            "IDDOCIDMATCH": "ID No Match of Document",
            "IDDOCNAMEMATCH": "Name Match of Document",
            "IDDOCSURNAMEMATCH": "Surname Match of Document",
            "FACELIVENESSGESTURE": "Face Mimic Detection",
            "FACELIVENESSAI": "Liveness AI Score",
            "MRZBIRTHDATECHECKSUM": "Date of Birth Check Digit",
            "MRZEXPDATECHECKSUM": "Expiry Date Check Digit",
            "MRZDOCNUMCHECKSUM": "Document Number Check Digit",
            "NFCMRZSIMILARITY": "NFC - MRZ Similarity",
            "NFCOCRFACESIMILARITY": "NFC - OCR Face Similarity",
            "NFCOCRSIMILARITY": "NFC - OCR Similarity",
            "AMLVALIDATION": "AML Check",
            "NVIPROVEIDWITHHOLDER": "NVI Check",
            "OCRLETTERCASEMATCH": "Letter Case Match Check",
            "OCRMRZSIMILARITY": "OCR - MRZ Similarity",
            "OCRNFCFACESIMILARITY": "OCR - NFC Face Similarity",
            "OCRNFCSIMILARITY": "OCR - NFC Similarity",
            "OLDIDSECONDARYINFORMATION": "Area Match Check",
            "IDCARD": "TR ID Card",
            "OLDIDCARD": "TR Old ID",
            "PASSPORT": "Passport",
            "DRIVERCARD": "TR Driving Licence",
            "FOREIGNID": "Residence Permit Document",
            "FOREIGNIDTYPEA": "Residence Permit",
            "FOREIGNIDTYPEB": "Residence Permit",
            "FOREIGNIDTYPEC": "Residence Permit",
            "FOREIGNIDTYPED": "Temporary Protection ID Card",
            "SCREENATTACK": "Screen Attack Safety",
            "hologram": "Hologram",
            "hiddenphoto": "Portrait (Ghost)",
            "newidfront": "ID Front (Processed)",
            "oldidfront": "Old ID Front (Processed) ",
            "signature": "Signature",
            "livenessframe": "Liveness Full Face",
            "passport": "Passport",
            "secondaryidfile": "ID Back (Processed)",
            "face": "Liveness Video",
            "newidface": "Portrait",
            "nfcbiometricpicture": "Portrait (NFC)",
            "driverface": "Driver Portrait",
            "newdriverfront": "TR Driving Licence",
            "turkishbluecardface": "Blue Card Portrait",
            "turkishbluecardfront": "Blue Card Front",
            "ocr": "OCR",
            "oldidface": "Old ID Face",
            "browser": "Browser",
            "city": "City",
            "country": "Country",
            "device": "Device",
            "ip": "IP",
            "language": "Language",
            "Identity No": "Identity No",
            "Document No": "Document No",
            "Licence Number": "Licence Number",
            "Passport No": "Passport No",
            "not-found": "Document not found",
            'identity_no': 'Identity No',
            'name': 'Name',
            'surname': 'Surname',
            'birthdate': 'Date of Birth',
            'document_no': 'Document No',
            'valid_until': 'Valid Until',
            'nationality': 'Nationality',
            "gender": "Gender",
            "mother_name": "Mother Name",
            "father_name": "Father Name",
            "birth_place": "Birth Place",
            "alternativeName": "Alternative Name",
            "alternativeSurname": "Alternative Surname",
            "agent": "Agent",
            "agent_lead": "Agent Lead",
            "admin": "Admin",
            "EXPIREDOC": "The document has expired",
            "NOTFOUNDDOC": "Document not found",
            "WRONGDOCTYPE": "Document type wrong",
            "NOTORIGINAL": "The document is not original",
            'select_language': 'Select Country',
            'select_language_desc': 'Please select your country to continue with ID card verification.',
            'select_template': 'Choose Template Type',
            'select_template_desc': 'To continue with ID card verification, please select your template type.',
            'FOREIGNIDBACK': 'Foreign ID (Back)',
            "ID-Front": "Front of ID Card",
            "ID-Back": "Back of ID Card",
            "ID": "ID Card",
            "ID-NFC": "NFC Reading",
            "Hologram": "Hologram Video",
            "Liveness": "Liveness Video",
            "Others": "Other Controls",
            "NFC Controls": "NFC Certificate Checks",
            "Metadata": "OCR Results & Meta Data",
            "User Approval Screen": "Users Waiting for Approval",
            "CONFIRMED": "Approved",
            "TECHNICAL_FAILURE": "Could not continue due to a technical fault",
            "CUSTOMER_FAILURE": "Could not continue due to customer",
            "_Status": "Status",
            "login_attempt": "Your user has an unsuccessful login attempt on {{ date }}",
            "HEAD-HORIZONTAL": " - Horizontal Head Movement Gesture Video",
            "HEAD-VERTICAL": " - Vertical Head Movement Gesture Video",
            "EYE": " - Eye Gesture Video",
            "MOUTH": " - Mouth Gesture Video",
            "HH": " - Horizontal Head Movement Gesture Video",
            "HV": " - Vertical Head Movement Gesture Video",
            "E": " - Eye Gesture Video",
            "M": " - Mouth Gesture Video",
            "Acceptance Threshold": "Acceptance Threshold",
            "Security Threshold": "Security Threshold",
            "new_id": "TR New ID Front",
            "new_id_back": "TR New ID Back",
            "old_id": "TR Old ID Front",
            "old_id_back": "TR Old ID Back",
            "new_driver": "TR Driving Licence Front",
            "new_driver_back": "TR Driving Licence Back",
            "old_driver": "TR Old Driving Licence Front",
            "old_driver_back": "TR Old Driving Licence Back",
            "address_confirmation_document": "Address Confirmation Document",
            "signature_document": "Signature",
            "mobile": "Mobile",
            "answeredCallsCount": "Answered Calls",
            "answeredCallsRatio": "Answered Call Ratio (%)",
            "answeredCallsTotalWaitingTime": "Total Waiting Time for Answered Calls",
            "avgAnsweredCallsWaitingTime": "Average Waiting Time for Answered Calls",
            "avgMeetingTime": "Average Meeting Time",
            "avgWaitingTime": "Average Waiting Time",
            "maxWaitingTime": "Max. Waiting Time",
            "totalCallsCount": "Total Calls",
            "totalMeetingTime": "Total Meeting Time",
            "totalWaitingTime": "Total Waiting Time",
            "inbound.answeredCalls": "Total Number of Answered Calls",
            "inbound.rejectedCalls": "Total Number of Rejected Calls",
            "inbound.totalMeetingTime": "Total Meeting Time",
            "inbound.avgMeetingTime": "Average Meeting Time",
            "inbound.totalWaitingTime": "Total Call Response Time",
            "inbound.avgWaitingTime": "Average Call Response Time",
            "totalActiveTime": "Total Active Time",
            "totalPassiveTime": "Total Passive Time",
            "hostFullName": "Agent",
            "call_no": 'Call No',
            "queueInfo.queueName": 'Queue Name',
            "host.fullname": 'Person receiving call',
            "customer.fullName": 'Customer name',
            "queueEntryDate": 'Queue Entry Time',
            "waitingTime": 'Waiting Time',
            "meetingTime": 'Length of Meeting',
            "appointmentId": 'Appointment No',
            "$appointmentId": 'Status',
            "availableAgents": "Available agents",
            "host.fullName": "Person receiving call",
            "queueName": "Queue Name",
            "customerFullname": "Customer Name",
            "callReceivedTime": "Time of Call Reaching Agent",
            "meetingCompletedDate": "Meeting Completed Time",
            "response": "Status",
            "candidateQueueMeetingInfoId": "Call No",
            "SCREENATTACKBACK":"Screen Attack (Back)",
            "MRZCOMPOSITECHECKSUM":"Composite Check Digit",
            "MRZCOMPOSITECHECKSUMVALID":"Composite Check Digit Validity",
            "FACEPHOTOEXISTENCE":"Photo Existence Check",
            "CHIPDETECTION":"Chip Existence Check",
            "ACTIVE": "Active",
            "PASSIVE": "Deactivated",
            "Hand" : "Hand Landmark on ID",
            "Card-Hold-Liveliness" : "Liveness with Card",
            "HANDLANDMARKTYPEMATCH THUMBRIGHTDOWN" : "Hand Landmark ID Type Check",
            "HANDLANDMARKSCORE THUMBRIGHTDOWN" : "Covering the Bottom Right Corner of the ID",
            "HANDLANDMARKTYPEMATCH THUMBRIGHTUP" : "Hand Landmark ID Type Check",
            "HANDLANDMARKSCORE THUMBRIGHTUP" : "Covering the Upper Right Corner of the ID",
            "HANDLANDMARKTYPEMATCH THUMBLEFTDOWN" : "Hand Landmark ID Type Check",
            "HANDLANDMARKSCORE THUMBLEFTDOWN" : "Covering the Bottom Left Corner of the ID",
            "HANDLANDMARKTYPEMATCH THUMBLEFTUP" : "Hand Landmark ID Type Check",
            "HANDLANDMARKSCORE THUMBLEFTUP" : "Covering the Upper Left Corner of the ID",
            "LWICONLYFRONCARDTYPESIMILARITY" : "Card Type Check at Liveness with Card",
            "LWICONLYFRONTFACESIMILARITY (A-D)" : "Face Similarity Score via Liveness with Card",
            "LWICONLYFRONTOCRSIMILARITY" : "OCR Similarity Score via Liveness with Card",
            "LWICLIVENESSAI" : "Liveness AI Score Score via Liveness with Card",
            "LWICSELFIEFIRSTIDFACESIMILARITY(A-C)" : "Face Similarity across ID Cards via Liveness with Card",
            "PHOTOSHOPCONTROLBACK" : "Photoshop Check Back Face",
            "PHOTOSHOPCONTROL" : "Photoshop Check Front Face",
            "APPROVAL" : "Confirmation Check",
            "SERIALNUMBER" : "Serial Number Check",
            "THUMBLEFTUP" : "Hand Landmark on ID Check",
            "THUMBLEFTUPTHUMBLEFTUP" : "Hand Landmark Up Left",
            "THUMBRIGHTUP" : "Hand Landmark on ID Check",
            "THUMBRIGHTUPTHUMBRIGHTUP" : "Hand Landmark Up ",
            "THUMBLEFTDOWN" : "Hand Landmark on ID Check",
            "THUMBLEFTDOWNTHUMBLEFTDOWN" : "Hand Landmark Left Down",
            "THUMBRIGHTDOWN" : "Hand Landmark on ID Check",
            "THUMBRIGHTDOWNTHUMBRIGHTDOWN" : "Hand Landmark Down",
            "THUMBRIGHTLEFTUP" : "Hand Landmark on ID Check",
            "THUMBRIGHTLEFTUPTHUMBRIGHTLEFTUP" : "Hand Landmark Left Up",
            "THUMBRIGHTLEFTDOWN" : "Hand Landmark on ID Check",
            "THUMBRIGHTLEFTDOWNTHUMBRIGHTLEFTDOWN" : "Hand Landmark Left Down",
            "livenessonlyface" : "ID Card at Liveness with Card",
            "livenesswithidfront" : "Liveness with Card",
            "LWICONLYFRONTFACESIMILARITY" : "Face Similarity Score Across ID Cards via Liveness with Card",
            "LWICSELFIEFIRSTIDFACESIMILARITY" : "Face Similarity Score via Liveness with Card",
            "Voice" : "Voice Check Steps",
            "FWVLIVENESSAI SERIALNUMBER" : "Liveness AI Score at Serial Number Check",
            "FWVLIVENESSAI APPROVAL" : "Total Liveness Score at Confirmation Check",
            "FWVLIVENESSGESTURE SERIALNUMBER" : "Gesture Detection at Serial Number Check",
            "FWVLIVENESSFACECONSAVG SERIALNUMBER" : "Average Face Similarity Score at Serial Number Check",
            "FWVLIVENESSFACECONSPER SERIALNUMBER" : "Valid Face Ratio at Serial Number Check",
            "FWVFACE SERIALNUMBER" : "Face Similarity Score at Serial Number Check",
            "FWVAGE SERIALNUMBER" : "Age Prediction at Serial Number Check",
            "FWVGENDER SERIALNUMBER" : "Gender Prediction at Serial Number Check",
            "FWVSPEECHMATCH SERIALNUMBER" :"Expression Accuracy at Serial Number Check",
            "FWVLIVENESS APPROVAL" : "Total Liveness Score at Confirmation Check",
            "FWVLIVENESSGESTURE APPROVAL" : "Gesture Detection at Confirmation Check",
            "FWVLIVENESSFACECONSAVG APPROVAL" : "Average Face Similarity Score at Confirmation Check",
            "FWVLIVENESSFACECONSPER APPROVAL" : "Valid Face Ratio at Confirmation Check",
            "FWVFACE APPROVAL" : "Face Similarity Score at Confirmation Check",
            "FWVAGE APPROVAL" : "Age Prediction at Confirmation Check",
            "FWVGENDER APPROVAL" : "Gender Prediction at Confirmation Check",
            "FWVSPEECHMATCH APPROVAL" : "Expression Accuracy at Confirmation Check",
            "FWVSPEECHCONSISTENCY APPROVAL" : "Voice Compatibility at Confirmation Check",
            "SECURITYOBJECTVERIFICATION" : "Interpretation of Security Elements"
        }
    },
    ru: {
        translation: {
            "Name Surname": "Имя Фамилия",
            "Registration Date": "Дата регистрации",
            "Submitted Files": "Отправленные файлы",
            "Submit": "Отправить",
            "Status": "Статус",
            "Verification Score": "Оценка проверки",
            "Agent": "Агент",
            "agent": "Агент",
            "agent_lead": "Ведущий агент",
            "admin": "Администратор",
            "Control": "Контроль",
            "Result": "Результат",
            "Outcome": "Исход",
            "Entry Type": "Тип записи",
            "Entry Score": "Оценка записи",
            "Entry Interpretation": "Интерпертация записи",
            "Validation": "Проверка",
            "Acceptable": "Приемлемо",
            "Media Type": "Тип носителя",
            "Actions": "Действия",
            "Open": "Открыть",
            "Download": "Скачать",
            "Meta Title": "Мета-заголовок",
            "Value": "Значение",
            "Info Title": "Заголовок информации",
            "Couldn't get media information": "Не удалось получить информацию о носителе",
            "Couldn't get export": "Не удалось получить экспорт",
            "Identity Acceptable": "Идентификация приемлема",
            "Identity Information Validity": "Срок действия идентификационной информации",
            "Biometric Photo Validity": "Срок действия биометрических фотографий",
            "Encryption Validity": "Срок действия шифрования",
            "Certificate Validity": "Срок действия сертификата",
            "Created At": "Создано",
            "Name": "Имя",
            "Surname": "Фамилия",
            "Birth Date": "Дата рождения",
            "Document Type": "Тип документа",
            "Identity No": "Идентификационный номер",
            "Expiry Date": "Дата истечения срока",
            "Delete Confirmation": "Удалить подтверждение",
            "The data you selected will be completely removed. Do you want to continue?": "Выбранные данные будут полностью удалены. Продолжить?",
            "Cancel": "Отмена",
            "Confirm": "Подтвердить",
            "Control Results": "Результаты контроля",
            "Confidence": "Надежность",
            "Identity": "Личность",
            "Video & Image": "Видео и изображеие",
            "Metadata": "Результаты и мета дата OCR",
            "NFC Controls": "Проверка сертификата NFC",
            "Please Wait...": "Пожалуйста ожидайте...",
            "Details": "Детали",
            "Export": "Экспорт",
            "Report": "Отчет",
            "Rows per page:": "Строки на странице",
            "Home": "Назад",
            "Person": "Личность",
            "Settings": "Настройки",
            "Logout": "Выйти",
            "Support": "Поддержка",
            "Verifications": "Проверки",
            "Verification Details": "Детали проверки",
            "Verification Result": "Результаты проверки",
            "Awaiting Calls": "Ожидание звонков",
            "Awaiting Calls (Live)": "Ожидание звонков (в прямом эфире)",
            "Call": "Звонок",
            "Waiting Time": "Время ожидания",
            "You will return to back page. Do you want to continue?": "Вы вернетесь на предыдущую страницу. Вы хотите продолжить?",
            "ID Info": "Идентификационная информация",
            "NFC Info": "Инофрмация с NFC",
            "Controls": "Управления",
            "Media": "Носитель",
            "Signing, Please Wait...": "Идет подписание. Пожалуйста ожидайте...",
            "Customers": "Клиенты",
            "oldidface": "Лицо со старого удостоверения личности",
            "Search Name": "Поиск имени",
            "Last Attempt": "Последняя попытка",
            "Phone": "Номер телефона",
            "E-Mail": "Электронная почта",
            "Customer Details": "Информация о клиенте",
            "Transaction ID": "Номер транзакции",
            "Verification Date": "Дата проверки",
            "city": "Город",
            "country": "Страна",
            "device": "Устройство",
            "ip": "IP",
            "language": "Язык",
            "Document No": "Номер документа",
            "Licence Number": "Номер лицензии",
            "Passport No": "Номер паспорта",
            "Awaiting Documents": "Ожидание документов",
            "Confirm Document": "Подтверждение документов",
            "Confirmed Documents": "Подтвержденные документы",
            "Document Details": "Информация о документе",
            "Identity Number": "Идентификационный номер",
            "Decline": "Отклонить",
            "This document was marked as CONFIRMED.": "Этот документ отмечен как ПОДТВЕРЖДЕННЫЙ",
            "Do you confirm?": "Вы подтверждаете?",
            "Check Again": "Проверьте еще раз",
            "This document was REJECTED.": "Этот документ был ОТКЛОНЕН",
            "Choose the reason for rejection": "Выберите причину для отклонения",
            "The document has expired": "Срок действия документа истек",
            "Document not found": "Документ не найден",
            "not-found": "Документ не найден",
            "Document type wrong": "Неверный тип документа",
            "Confirmation Date": "Дата подтверждения",
            "Confirmation Status": "Статус подтверждения",
            "Save": "Сохранить",
            "Confirm or decline this document.": "Подтвердите или отклоните документ",
            "Operation Completed": "Операция завершена",
            "You are closing the selected document without making any choice. This document will not be available for confirmation for 3 minutes. Do you confirm?": "Вы закрываете выбранный документ, не осуществив какой либо выбор. Этот документ будет недоступен для подтверждения около 3 минут. Вы подтверждаете?",
            "Go back to the document": "Вернуться к документу",
            "Change as Declined": "Изменить как отклоненный",
            "Change as Approved": "Изменить как одобрено",
            "Do you want to change?": "Вы хотите внести изменения?",
            "Document current status": "Текущий статус документа",
            "CONFIRMED": "ПОДТВЕРЖДЕН",
            "DECLINED": "ОТКЛОНЕН",
            "Personel Incorrect Entry": "Неправильный ввод личных данных",
            "Document Barcode Number Incorrect Entry": "Неправильный ввод номера штрих-кода документа",
            "TCKN Incorrect Entry": "Неправильный ввод TCKN",
            "Why is the document's \"Approved\" status changed to \"Declined\"?": "Почему статус документа \"Подтвержден\" изменен на \"Отклонен\"?",
            "Why is the document's \"Declined\" status changed to \"Approved\"?": "Почему статус документа \"Отклонен\" изменен на \"Подтвержден\"?",
            "Date": "Дата",
            "Action": "Действие",
            "Document History": "История документа",
            "Blue Card": "Синяя карта",
            "Residence Permit": "Вид на жительство",
            "Temporary Protection ID": "Удостоверение личности временной защиты",
            "German ID Card": "Немецкое удостоверение личности",
            "Approved": "Одобренный",
            "Document Detail": "Сведения о документе",
            "Verification Detail": "Сведения о проверке",
            "Change Status": "Статус об изменении",
            "Please enter same password": "Пожалуйста введите повторно пароль",
            "Back To Panel": "Назад к панели",
            "Could not change your password": "Не удалость изменить ваш пароль",
            "Old Password": "Старый пароль",
            "New Password": "Новый пароль",
            "Confirm New Password": "Подтвердите новый пароль",
            "Save Password": "Сохранить пароль",
            "Change Password": "Изменить пароль",
            "Forgot Password?": "Забыли пароль?",
            "Reset Password": "Сбросить пароль",
            "E-mail": "Электронный адрес",
            "Return": "Вернуться",
            "Create New Password": "Создать новый пароль",
            "Could not reset your password": "Не удалость сбросить ваш пароль",
            "Check your E-mail": "Проверьте вашу электронную почту",
            "Check your username": "Проверьте вашу фамилию",
            "Sign In": "Войти",
            "Password": "Пароль",
            "Remember Me": "Запомнить меня",
            "Create User": "Создать пользователя",
            "Approve Users": "Одобрить пользователей",
            "Approve": "Одобрить",
            "Create": "Создать",
            "User Approval Screen": "Страница одобрения пользователя",
            "The document is not original": "Документ не является оригиналом",
            "Select a reason for rejection of the document": "Выберите причину отклонения документа",
            "Uploaded": "Загружено",
            "Awaiting": "Ожидание",
            "Declined: Expired document": "Отклонено: Документ просрочен",
            "Declined: Document not found": "Отклонено: Документ не найден",
            "Declined: Document type wrong": "Отклонено: Неверный тип документа",
            "Declined: Document is not original": "Отклонено: Документ не является оригиналом",
            "Changed to declined: TCKN Incorrect Entry": "Изменено на отклонено: Неверный ввод TCKN",
            "Changed to declined: Document Barcode Number Incorrect Entry": "Изменено на отклонено: Неправильный ввод номера штрих-кода документа",
            "Changed to declined: Personel Incorrect Entry": "Изменено на отклонено: Неправильный ввод личных данных",
            "Declined": "Отклонено",
            "Changed to approved: Personel Incorrect Entry": "Изменено на одобрено: Неправильный ввод личных данных",
            "All": "Все",
            "Clear All": "Очистить все",
            "Search": "Поиск",
            "Additional Info": "Дополнительная информация",
            "Phone Number": "Номер телефона",
            'identity_no': 'Идентификационный номер',
            'name': 'Имя',
            'surname': 'Фамилия',
            'birthdate': 'Дата рождения',
            'document_no': 'Номер документа',
            'valid_until': 'Действителен до',
            'nationality': 'Национальность',
            "gender": "Пол",
            "mother_name": "Имя матери",
            "father_name": "Имя отца",
            "birth_place": "Место рождения",
            "The operation could not be performed due to an error.": "Операция не может быть выполнена из-за ошибки.",
            "We Value Your Privacy Preferences": "Мы ценим ваши предпочтения в отношении конфиденциальности",
            "This website gathers information about user interaction, personel information and biometric characteristics for secure consent collection.  This sensitive information will only enable you to sign documents securely and verifiably and will not be shared with third parties without your consent. Please confirm your consents to proceed.": "Этот веб-сайт собирает информацию о взаимодействии с пользователем, личную информацию и биометрические характеристики для безопасного сбора согласия. Эта конфиденциальная информация позволит вам безопасно и достоверно подписывать документы и не будет передаваться третьим лицам без вашего согласия. Пожалуйста, подтвердите свое согласие, чтобы продолжить.",
            "I have read and accepted the GDPR Complience": "Я прочитал и принял соглашение о об Общем регламенте по защите персональных данных",
            "Transaction Id": "Номер транзакции",
            "Gesture": "Жест",
            "Please select valid date ranges": "Пожалуйста, выберите допустимые диапазоны дат",
            "ID Type": "Тип удостоверения личности",
            "Progress": "Продвижение",
            "ID Front": "Лицевая сторона удостоверения личности",
            "ID Back": "Обратная сторона удостоверения личности",
            "Liveness": "Видео живости",
            "Address Document": "Адресный документ",
            "Biometric Recognition & Verification": "Биометрическое распознавание и проверка",
            "Please select one of the following identification documents for verification.": "Пожалуйста, выберите один из следующих документов, удостоверяющих личность, для проверки.",
            "For verification, first your ID photo, then your face photo is taken": "Для проверки сначала делается фотография вашего удостоверения личности, а затем фотография вашего лица.",
            "The verification result is created by evaluating the identity and face information.": "Результат проверки создается путем оценки личности и информации о лице.",
            "Authentications without user creation cannot be connected with any account and all information is deleted when you leave the page": "Аутентификации без создания пользователя не могут быть связаны с какой-либо учетной записью и вся информация удаляется, когда вы покидаете страницу.",
            "Authentications made by creating a user are linked to the user account and the results can be viewed through the ProveID Dashboard panel.": "Аутентификации, выполненные путем создания пользователя, связаны с учетной записью пользователя и результаты можно просмотреть на панели ProveID Dashboard.",
            "EXPIREDOC": "Документ просрочен",
            "NOTFOUNDDOC": "Документ не найден",
            "WRONGDOCTYPE": "Неверный тип документа",
            "NOTORIGINAL": "Документ не является оригиналом",
            "select_language": "Выбрать страну",
            "select_language_desc": "Пожалуйста выберите страну для продолжения проверки удостоверения личности.",
            "select_template": "Выберите тип шаблона",
            "select_template_desc": "Для продолжения проверки удостоверения личности, полжалуйста выберите тип шаблона.",
            "FOREIGNIDBACK": "Удостоверение личности иностранного государства (Back)",
            "Go Back To Dashboard": "Вернуться к панели управления",
            "ID-Front": "Лицевая сторона удостоверения личности",
            "ID-Back": "Задняя сторона удостоверения личности",
            "ID-NFC": "Чтение NFC",
            "Hologram": "Видео голограммы",
            "Others": "Удостоверения личности всего мира",
            "Acceptance Threshold": "Acceptance Threshold",
            "Security Threshold": "Security Threshold",
            "Do you want to confirm to deactivate user {{user}}?" : "{{user}} kullanıcısını pasife almayı onaylıyor musunuz?",
            "Do you want to decline to deactivate user {{user}}?" : "{{user}} kullanıcısını pasife alma talebini reddetmek mi istiyorsunuz?",
            "Do you want to confirm to delete user {{user}}?" : "{{user}} kullanıcısını silme talebini onyalamak istediğinizden emin misiniz?",
            "Do you want to decline to delete user {{user}}?" : "{{user}} kullanıcısının silme talebini reddetmek istediğinizden emin misiniz?",
            "Your password has been expired. Please reset your password first" : "Şifrenizin süresi dolmuştur. Lütfen önce şifrenizi sıfırlayınız",
            "Set New Password" : "Yeni Şifre Belirle",
        }
    }
};

let query = queryString.parse(window.location.search);
let lg = null;

if (sessionStorage.getItem('lg') === null || sessionStorage.getItem('lg') === '') {
    lg = 'en';
    sessionStorage.setItem('lg', 'en')
} else {
    lg = sessionStorage.getItem('lg')
}

try {
    if (query.lg && query.lg !== '' && query.lg in resources) {
        sessionStorage.setItem('lg', query.lg);
        lg = query.lg;
    } else {
        lg = sessionStorage.getItem('lg')
    }


} catch (e) {
    console.log(e);
}


i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lg,
        keySeparator: false, // we do not use keys in form messages.welcome
        nsSeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n
