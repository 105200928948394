import React, {Component} from "react";
import {Button, Card, CircularProgress, FormControlLabel, FormGroup, Grid, withStyles} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {withSnackbar} from 'material-ui-snackbar-provider';
import {withTranslation} from 'react-i18next';
import Select from 'react-select';
import i18n from "i18next";


import customConstants from "Constants";
import Checkbox from "@material-ui/core/Checkbox";

let _this;

const styles = theme => ({
    wrapper: {
        position: "relative",
        backgroundColor: '#fff'
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
});


class CreateUser extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            phone: "",
            surname: "",
            userType: "agent_lead",
            userRole: "",
            externalId: "",
            userRoleSub: [],
            loading: false,
            roles: []
        };

        _this = this;
    }


    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSubRoles = (event) => {

        let current = this.state.userRoleSub

        if (!current.includes(event.target.name)) {
            current.push(event.target.name);
        } else {
            var index = current.indexOf(event.target.name);
            if (index > -1) { //if found
                current.splice(index, 1);
            }
        }

        console.log(current)

        this.setState({userRoleSub: current}, () => {
            console.log(this.state.userRoleSub)
        });
    };

    handleOptionChangeType = selectedOption => {
        this.setState({"userType": selectedOption.value});
    };

    handleOptionChangeRole = selectedOption => {
        this.setState({"userRole": selectedOption.value});
    };

    handleFormSubmit = () => {
        if (this.state.name === '' || this.state.email === '' || this.state.surname === '' || this.state.userType === '') {
            this.props.snackbar.showMessage(this.props.t("Please enter all fields correctly"), 'warning');
            console.log(this.state);
            return;
        }
        this.setState({loading: true});
        if (this.state.userRole != "") {
            this.setState({"selectedRoles": [this.state.userType, ...this.state.userRole.split(",")]});
        } else {
            this.setState({"selectedRoles": [this.state.userType]});
        }
        axios.post(customConstants.API_URL + "/assess/create-approval-request", {
            "requestType": "USER_CREATE",
            "entity": {
                "username": this.state.email,
                "name": this.state.name,
                "surname": this.state.surname,
                "mobile": this.state.phone,
                "roles": this.state.selectedRoles.concat(this.state.userRoleSub),
                "language": i18n.language,
                "externalId": this.state.externalId,
            }
        }).then((res) => {
            this.setState({
                name: "",
                email: "",
                surname: "",
                phone: "",
                userType: "admin",
                userRole: "",
                loading: false,
                externalId: false,
            })
            if (res.status >= 200 && res.status < 300) {
                this.props.snackbar.showMessage(this.props.t("Operation Completed"), 'success');
            } else {
                this.props.snackbar.showMessage(this.props.t("Could not create user"), 'error');
            }
        }).catch((error) => {
            this.setState({loading: false})
            this.props.snackbar.showMessage(this.props.t("Server Error"), 'error');
        })
    };

    componentDidMount() {
        this.setState({loading: true})
        axios.get(customConstants.API_URL + "/user/role-types").then((res) => {
            this.setState({
                roles: res.data.map((role) => ({
                    value: role.roleName,
                    label: role.roleName,
                    isPrimary: role.isPrimary
                })),
                loading: false
            }, () => {
            })
        }).catch((error) => {
            this.setState({loading: false})
            this.props.snackbar.showMessage(this.props.t("Server Error"), 'error');
        })
    }

    render() {
        let {name, email, surname, userType, userRole, phone,externalId} = this.state;
        let {classes} = this.props;

        const customOptions = [
            {value: 'admin', label: 'Admin'},
            {value: 'agent', label: 'Agent'},
            {value: 'agent_lead', label: 'Agent Lead'},
            {value: 'base_user', label: 'Base User'},
        ];

        const customRoles = [
            {value: "", label: this.props.t('No Role')},
            {value: 'maker', label: this.props.t('Maker')},
            {value: 'checker', label: this.props.t('Checker')},
            {value: 'maker,checker', label: this.props.t('Maker & Checker')},
        ]

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                zIndex: 99999999
            }),
            container: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                zIndex: 99999999
            }),
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                padding: '5px 0'
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';
                return {...provided, opacity, transition};
            }
        }

        return (
            <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor: '#021633'}}>
                <div className="p-8">
                    <Card className="signup-card position-relative y-center" style={{backgroundColor: '#092A5C'}}>
                        <Grid container>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <div className="p-32 flex flex-center flex-middle h-100">
                                    <img src="/assets/images/logos/proveid.svg" alt=""/>
                                </div>
                            </Grid>
                            <Grid item lg={7} md={7} sm={7} xs={12} style={{backgroundColor: '#fff'}}>
                                <div className="p-36 h-100 bg-light-gray position-relative">
                                    <h3 style={{marginBottom: "20px"}}>{this.props.t("Create User")}</h3>
                                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                        <div style={{flexDirection: 'row', display: 'flex', gap: 10, width: '100%'}}>
                                            <TextValidator
                                                className="mb-16 w-100 d-flex"
                                                label={this.props.t("Name")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="name"
                                                type="text"
                                                value={name}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                            <TextValidator
                                                className="mb-16 w-100 d-flex"
                                                label={this.props.t("Surname")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="surname"
                                                type="text"
                                                value={surname}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                        </div>

                                        <TextValidator
                                            className="mb-16 w-100"
                                            label={this.props.t("E-mail")}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            name="email"
                                            type="email"
                                            value={email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                        />
                                        <div style={{flexDirection: 'row', display: 'flex', gap: 10, width: '100%'}}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={this.props.t("Phone")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="phone"
                                                type="text"
                                                placeholder="5554443322"
                                                value={phone}
                                                validators={['required', 'matchRegexp:^5[0-9]{9}$']}
                                                errorMessages={['this field is required', 'phone is not valid']}
                                            />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={this.props.t("External Id")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="externalId"
                                                type="text"
                                                placeholder="1234567890"
                                                value={externalId}
                                            />
                                        </div>
                                        <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
                                            <Select
                                                className="mb-16 w-100 select-box-role"
                                                defaultValue={customOptions[2]}
                                                styles={customStyles}
                                                inputId="userType"
                                                name="userType"
                                                onChange={this.handleOptionChangeType}
                                                options={
                                                    this.state.roles.filter(function (role) {
                                                        if (role.isPrimary === true) {
                                                            role.label = role.label.replaceAll('_', ' ').replaceAll(/\b\w/g, function (match) {
                                                                return match.toUpperCase();
                                                            })
                                                            return role
                                                        }
                                                    })
                                                }
                                            />


                                        </div>
                                        <FormGroup>
                                            <Grid container spacing={2}>

                                                {
                                                    this.state.roles.map(function (role) {
                                                        if (role.isPrimary === false) {
                                                            role.label = role.label.replaceAll('_', ' ').replaceAll(/\b\w/g, function (match) {
                                                                return match.toUpperCase();
                                                            })
                                                            return <Grid container item xs={6}
                                                                         style={{paddingTop: 0, paddingBottom: 0}}
                                                                         direction="column"><FormControlLabel required
                                                                                                              control={
                                                                                                                  <Checkbox
                                                                                                                      name={role.value}
                                                                                                                      onChange={_this.handleChangeSubRoles}/>}
                                                                                                              label={role.label}/></Grid>
                                                        }
                                                    })
                                                }

                                            </Grid>
                                        </FormGroup>
                                        <div className="flex flex-middle" style={{marginTop: 20, marginBottom: 30}}>
                                            <div className={classes.wrapper} style={{width: "100%"}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={this.state.loading}
                                                    style={{width: "100%"}}
                                                >
                                                    {this.props.t("Create")}
                                                </Button>
                                                {this.state.loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div style={{textAlign: 'center', marginBottom: 40}}><a href={'#'}
                                                                                                onClick={() => this.props.history.push("/")}>{this.props.t("Back To Panel")}</a>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(withSnackbar()(withRouter(
    CreateUser
))));
